import React, { useEffect, useState } from 'react';
import { getDownloadURL, ref } from 'firebase/storage';
import { useFirebase } from '../../context/Firebase';

const StandardsCard = (props) => {
    const baseClass = "standards-card";

    const [imageUrl, setImageUrl] = useState('');
    const { storage } = useFirebase();

    useEffect(() => {
        const imagePath = 'standardImages/' + props.image;
        getDownloadURL(ref(storage, imagePath)).then((url) => {
            setImageUrl(url);
        }).catch((error) => {console.log("url fetch error : ", error.message)});
    }, [props.image, storage]);

    return (
        <div className={baseClass}>
            <img className={`${baseClass}__image`} src={imageUrl} alt='img' />
            <div className={`${baseClass}__title`} dangerouslySetInnerHTML={{ __html: props.title }} />
            <div className={`${baseClass}__description`} dangerouslySetInnerHTML={{ __html: props.description }} />
        </div>
    )
}

export default StandardsCard;