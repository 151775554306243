import React, { useEffect, useState } from 'react';
import TestimonialCarousel from './TestimonialCarousel';
import { collection, getDocs } from 'firebase/firestore';
import { useFirebase } from '../../context/Firebase';

const Testimonial = (props) => {
    const baseClass = "testimonial";

    const [testimonialCards, setTestimonialCards] = useState([]);
    const { firestore } = useFirebase();
    
    useEffect(() => {
        const getTestimonialData = async () => {
            const database = await getDocs(collection(firestore, 'testimonials'));
            setTestimonialCards(database.docs.map((val) => {
                return val._document.data.value.mapValue.fields
            }).map((data) => {
                return {
                    name: data.name.stringValue,
                    date: data.date.stringValue,
                    testimonial: data.testimonial.stringValue
                }
            }));
        }

        getTestimonialData();
    }, [firestore]);

    return (
        testimonialCards && testimonialCards.length > 0 && <div className={baseClass}>
            <div className={`${baseClass}__container`}>
                <div className={`${baseClass}__title`} dangerouslySetInnerHTML={{__html: props.title}} />
                <TestimonialCarousel 
                    testimonialCards={testimonialCards}
                />
            </div>
        </div>
    )
}

export default Testimonial;