import React, { useEffect, useState } from 'react';
import { getDownloadURL, listAll, ref } from 'firebase/storage';
import { useFirebase } from '../../context/Firebase';

const MiddleBanner = () => {
    const baseClass = "middle-banner";

    const [middleBannerImage, setMiddleBannerImage] = useState('');
    const { storage } = useFirebase();

    useEffect(() => {
        listAll(ref(storage, 'bannerImages/middleBannerImages')).then((res) => {
        if(res.items.length > 0) {
            getDownloadURL(res.items[0]).then((url) => {
            setMiddleBannerImage(url);
            }).catch((error) => {console.log("url fetch error : ", error.message)});
        }
        }).catch((error) => {console.log("image ref error : ", error.message)});
    }, [storage]);

    return (
        middleBannerImage !== '' && <div className={baseClass}> <img src={middleBannerImage} alt="middle banner" /> </div>
    )
}

export default MiddleBanner;