import React from 'react';

const TestimonialCard = (props) => {
    const baseClass = "testimonial-card";

    return (
        <div className={baseClass}>
            <div className={`${baseClass}__name`} dangerouslySetInnerHTML={{__html: props.name}} />
            <div className={`${baseClass}__date`} dangerouslySetInnerHTML={{__html: props.date}} />
            <div className={`${baseClass}__testimonial`} dangerouslySetInnerHTML={{__html: props.testimonial}} />
        </div>
    )
}

export default TestimonialCard;