import React, { useState, useEffect } from 'react';
import { useFirebase } from '../../context/Firebase';
import { getDownloadURL, ref } from 'firebase/storage';

const MenuModal = (props) => {
    const baseClass = "menu-modal";

    const [imageUrl, setImageUrl] = useState('');
    const { storage } = useFirebase();

    useEffect(() => {
        const imagePath = 'menuImages/' + props.image;
        getDownloadURL(ref(storage, imagePath)).then((url) => {
            setImageUrl(url);
        }).catch((error) => {console.log("url fetch error : ", error.message)});
    }, [props.image, storage]);

    return (
        <div className={baseClass} onClick={(e) => {if (e.target.className === baseClass) props.closeModal()}}>
            <div className={`${baseClass}__container`}>
                <img className={`${baseClass}__image`} src={imageUrl} alt="please wait loading menu" />
                <div className={`${baseClass}__details`}>
                    <div className={`${baseClass}__title`} dangerouslySetInnerHTML={{__html: props.title}} />
                    <div className={`${baseClass}__price-time`} dangerouslySetInnerHTML={{__html: `${props.price ? "₹ " + props.price : ""}${props.time ? (props.price ? " - " : "") + (props.time >= 60 ? Math.floor(props.time / 60) + " hr " + (props.time % 60) + " min" : props.time + " min") : ""}`}} />
                    <div className={`${baseClass}__details__body`}>
                        {props.pointers && props.pointers.length > 0 &&
                            <ul className={`${baseClass}__pointers`}>
                                {props.pointers.map((point, i) => {
                                    return(
                                        <li key={i} dangerouslySetInnerHTML={{__html: `${point}`}} />
                                    );
                                })}
                            </ul>
                        }
                        {props.pointers && props.description && props.pointers.length > 0 && <div className={`${baseClass}__details__body__separator`} />}
                        {props.description && <div className={`${baseClass}__description`} dangerouslySetInnerHTML={{__html: props.description}} />}
                    </div>
                </div>
                <i className={`fa-solid fa-xmark ${baseClass}__close-btn`} onClick={() => props.closeModal()} />
            </div>
        </div>
    )
}

export default MenuModal;