import { collection, getDocs } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useFirebase } from '../../context/Firebase';

const AboutUs = (props) => {
    const baseClass = "about-us";

    const [description, setDescription] = useState('');
    const { firestore } = useFirebase();

    useEffect(() => {
        const getDescription = async () => {
            const database = await getDocs(collection(firestore, 'aboutus'));
            setDescription(database.docs[0]._document.data.value.mapValue.fields.description.stringValue);
        }

        getDescription();
    }, [firestore]);

    

    return (
        <div className={baseClass}>
            <div className={`${baseClass}__container`}>
                <div className={`${baseClass}__title`} dangerouslySetInnerHTML={{__html: props.title}} />
                <div className={`${baseClass}__description`} dangerouslySetInnerHTML={{__html: description}} />
            </div>
        </div>
    )
}

export default AboutUs;