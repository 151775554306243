import React, { useEffect, useState } from 'react';
import MenuCard from './MenuCard';
import MenuModal from './MenuModal';
import BookModal from './BookModal';

const Menu = (props) => {
    const baseClass = "menu";

    const [menuCards, setMenuCards] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showBookModal, setShowBookModal] = useState(false);
    const [menuCardData, setMenuCardData] = useState();

    useEffect(() => {
        setMenuCards(props.menus);
    }, [props.menus]);

    const menuCardClick = (menu) => {
        setShowModal(true);
        setMenuCardData(menu);
    }

    const menuBookClick = (menu) => {
        setShowBookModal(true);
        setMenuCardData(menu);
    }

    const closeMenuModal = () => {
        setShowModal(false);
        setShowBookModal(false);
        setMenuCardData();
    }

    return (
        menuCards && menuCards.length > 0 && <div className={baseClass}>
            <div className={`${baseClass}__container`}>
                <div className={`${baseClass}__title`} dangerouslySetInnerHTML={{ __html: props.title }} />
                {menuCards && menuCards.length > 0 && <div className={`${baseClass}__cards-wrapper`}>
                        {menuCards.map((card, i) => {
                            return (
                                <MenuCard
                                    key={i}
                                    {...card}
                                    handleCardClick={menuCardClick}
                                    handleBookClick={menuBookClick}
                                />
                            );
                        })}
                    </div>
                }
                {showModal && <MenuModal {...menuCardData} closeModal={closeMenuModal} />}
                {showBookModal && <BookModal {...menuCardData} closeModal={closeMenuModal} />}
            </div>
        </div>
    )
}

export default Menu;