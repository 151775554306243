import React, { useEffect, useState } from 'react';
import { useFirebase } from '../../context/Firebase';
import { getDownloadURL, ref } from 'firebase/storage';

const MenuCard = (props) => {
    const baseClass = "menu-card";

    const [imageUrl, setImageUrl] = useState('');
    const { storage } = useFirebase();

    useEffect(() => {
        const imagePath = 'menuImages/' + props.image;
        getDownloadURL(ref(storage, imagePath)).then((url) => {
            setImageUrl(url);
        }).catch((error) => {console.log("url fetch error : ", error.message)});
    }, [props.image, storage]);

    return (
        <div className={baseClass}>
            <img className={`${baseClass}__image`} src={imageUrl} alt="please wait loading menu" />
            <div className={`${baseClass}__details`}>
                <div className={`${baseClass}__header`}>
                    <div className={`${baseClass}__title`} dangerouslySetInnerHTML={{__html: props.title}} />
                    <div className={`${baseClass}__price-time`} dangerouslySetInnerHTML={{__html: `${props.price ? "₹ " + props.price : ""}${props.time ? (props.price ? " - " : "") + (props.time >= 60 ? Math.floor(props.time / 60) + " Hrs " + (props.time % 60) + " Minutes" : props.time + " Minutes") : ""}`}} />
                </div>
                <div className={`${baseClass}__body`}>
                    {props.pointers && props.pointers.length > 0 &&
                        <ul className={`${baseClass}__pointers`}>
                            {props.pointers.map((point, i) => {
                                return(
                                    <li key={i} dangerouslySetInnerHTML={{__html: point}} />
                                );
                            })}
                        </ul>
                    }
                    <div className={`${baseClass}__know-more`} dangerouslySetInnerHTML={{__html: "more"}} onClick={() => props.handleCardClick({...props})} />
                </div>
                <div className={`${baseClass}__book`} dangerouslySetInnerHTML={{__html: "Book Now"}} onClick={() => props.handleBookClick({...props})} />
            </div>
        </div>
    )
}

export default MenuCard;