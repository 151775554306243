import React, { createContext, useContext } from "react";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDdq8i8jXSGpfzmPGHroZ_spuIuNHPGZYs",
  authDomain: "darpan-ecd30.firebaseapp.com",
  databaseURL: "https://darpan-ecd30-default-rtdb.firebaseio.com",
  projectId: "darpan-ecd30",
  storageBucket: "darpan-ecd30.appspot.com",
  messagingSenderId: "955193866528",
  appId: "1:955193866528:web:9d295522f2709c1929bf45",
  measurementId: "G-GQ523WM0Q2",
};

const firebaseApp = initializeApp(firebaseConfig);
const firebaseAuth = getAuth(firebaseApp);
const firebaseStorage = getStorage(firebaseApp);
const firebaseFirestore = getFirestore(firebaseApp);

const FirebaseContext = createContext(null);

export const useFirebase = () => useContext(FirebaseContext);

export const FirebaseProvider = (props) => {
  return (
    <FirebaseContext.Provider
        value={{
            auth: firebaseAuth,
            storage: firebaseStorage,
            firestore: firebaseFirestore,
        }}
    >
        {props.children}
    </FirebaseContext.Provider>
  );
};
