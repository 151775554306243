import React, { useEffect, useState } from 'react';
import TestimonialCard from './TestimonialCard';
import { Pagination, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

const TestimonialCarousel = (props) => {
    const baseClass = "testimonial-carousel";

    const [width, setWidth] = useState(window.innerWidth > 1156 ? 1156 : window.innerWidth);

    const detectCurrentWidth = () => setWidth(window.innerWidth > 1156 ? 1156 : window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', detectCurrentWidth);
        return () => {
            window.removeEventListener('resize', detectCurrentWidth);
        }
    }, [width]);

    return (
        <div className={baseClass}>
            <Swiper
                slidesPerView={Math.round((width * 0.9) / 350)}
                spaceBetween={30}
                navigation={true}
                modules={[Pagination, Navigation]}
                className={`${baseClass}__cards-wrapper`}
            >
                {props.testimonialCards.map((testimonialCard, i) => {
                    return(
                        <SwiperSlide key={i}>
                            <TestimonialCard {...testimonialCard} />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </div>
    )
}

export default TestimonialCarousel;