import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.scss';
import Header from './modules/header/Header';
import Home from './pages/Home';
import Footer from './modules/footer/Footer';
import Services from './pages/Services';
import { onAuthStateChanged } from 'firebase/auth';
import { useFirebase } from './context/Firebase';

const App = () => {
  const baseClass = "app";

  const [currentUser, setCurrentUser] = useState();
  const { auth } = useFirebase();

  useEffect(() => {
    const isUserLoggedIn = async () => {
        await onAuthStateChanged(auth, (user) => { setCurrentUser(user); })
    }

    isUserLoggedIn();
}, [auth]);

  return (
    <div className={baseClass}>
      <Header />
      <Routes>
        <Route path='/' element={<Home user={currentUser} />} />
        {Boolean(currentUser) && <Route path='/services/:name' element={<Services />} />}
        <Route path="*" element={<Home user={currentUser} />} />
      </Routes>
      {Boolean(currentUser) && <Footer user={currentUser} />}
    </div>
  )
}

export default App;
