import { addDoc, collection } from 'firebase/firestore';
import React, { useState } from 'react';
import { useFirebase } from '../../context/Firebase';

const BookModal = (props) => {
    const baseClass = "book-modal";

    const [number, setNumber] = useState('');
    const [address, setAddress] = useState({
        lineOne: '',
        lineTwo: '',
        landmark: '',
        city: '',
        pinCode: ''
    });
    const { firestore } = useFirebase();

    const bookData = async (e) => {
        e.preventDefault();
        if(number !== "" && address !== "") {
            try {
                const bookingData = {
                    menuName: props.title,
                    price: props.price,
                    contact: number,
                    address: address
                }
                await addDoc(collection(firestore, "bookings"), bookingData)
                
            } catch(error) {console.log("Error writing to db")}
        }
        props.closeModal();
    }

    return (
        <div className={baseClass} onClick={(e) => {if (e.target.className === baseClass) props.closeModal()}}>
            <div className={`${baseClass}__container`}>
                <div className={`${baseClass}__title`} dangerouslySetInnerHTML={{__html: "Please provide your complete address and confirm booking"}} />
                <form className={`${baseClass}__form`} onSubmit={bookData}>
                    <input className={`${baseClass}__form__field`} type="number" placeholder='Phone Number' value={number} onChange={(e) => setNumber(e.target.value)} />
                        <input className={`${baseClass}__form__field`} type="text" placeholder='Address Line 1 (House No / Street / Area)' value={address.lineOne} onChange={(e) => setAddress({...address, lineOne: e.target.value})} />
                    <div className={`${baseClass}__form__fieldset`}>
                        <input className={`${baseClass}__form__field`} type="text" placeholder='Address Line 2' value={address.lineTwo} onChange={(e) => setAddress({...address, lineTwo: e.target.value})} />
                        <input className={`${baseClass}__form__field`} type="text" placeholder='Landmark' value={address.landmark} onChange={(e) => setAddress({...address, landmark: e.target.value})} />
                    </div>
                    <div className={`${baseClass}__form__fieldset`}>
                        <input className={`${baseClass}__form__field`} type="text" placeholder='City' value={address.city} onChange={(e) => setAddress({...address, city: e.target.value})} />
                        <input className={`${baseClass}__form__field`} type="number" placeholder='Pin Code' value={address.pinCode} onChange={(e) => setAddress({...address, pinCode: e.target.value})} />
                    </div>
                    <button type="submit" className={`${baseClass}__form__confirm-btn`} dangerouslySetInnerHTML={{__html: "Confirm Booking"}} />
                </form>
                <i className={`fa-solid fa-xmark ${baseClass}__close-btn`} onClick={() => props.closeModal()} />
            </div>
        </div>
    )
}

export default BookModal;