import { collection, getDocs } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useFirebase } from '../../context/Firebase';
import { NavLink } from 'react-router-dom';

const Footer = (props) => {
    const baseClass = "footer";

    const moreSection = ["About Us", "Privacy Policy", "FAQs", "Terms and Conditions"];

    const [categories, setCategories] = useState([]);
    const [contact, setContact] = useState([]);
    const [links, setLinks] = useState([]);
    const { firestore } = useFirebase();

    useEffect(() => {
        const getFooterData = async () => {
            (await getDocs(collection(firestore, 'footer'))).docs.forEach((val) => {
                var fields = val._document.data.value.mapValue.fields;
                var field;
                if (val.id === "contact") {
                    var contactData = [];
                    for (field in fields) { contactData.push(fields[field].stringValue) }
                    setContact(contactData);
                } else if (val.id === "sociallinks") {
                    var linkData = [];
                    for (field in fields) { linkData.push({ name: field, url: fields[field].stringValue }) }
                    setLinks(linkData);
                }
            });
            setCategories((await getDocs(collection(firestore, 'services'))).docs.map((val) => {
                return val._document.data.value.mapValue.fields;
            }).map((data) => {
                return data.name.stringValue;
            }));
        }

        getFooterData();
    }, [firestore]);

    return (
        <div className={baseClass} style={{marginTop: Boolean(props.user) ? 50 : 0}}>
            <div className={`${baseClass}__container`}>
                <div className={`${baseClass}__info-section`}>
                    <div className={`${baseClass}__info-section__sub-section`}>
                        <div className={`${baseClass}__info-section__sub-section__title`} dangerouslySetInnerHTML={{ __html: "More From Us" }} />
                        <div className={`${baseClass}__info-section__sub-section__items`}>
                            {moreSection.map((item, i) => { return (<div key={i} dangerouslySetInnerHTML={{ __html: item }} />) })}
                        </div>
                    </div>
                    <div className={`${baseClass}__info-section__sub-section`}>
                        <div className={`${baseClass}__info-section__sub-section__title`} dangerouslySetInnerHTML={{ __html: "Categories" }} />
                        <div className={`${baseClass}__info-section__sub-section__items`}>
                            {Boolean(props.user) && categories.map((item, i) => { return (<NavLink key={i} to={`/services/${item.toLowerCase().split(' ').join('-')}`} dangerouslySetInnerHTML={{ __html: item }} />) })}
                        </div>
                    </div>
                    <div className={`${baseClass}__info-section__sub-section`}>
                        <div className={`${baseClass}__info-section__sub-section__title`} dangerouslySetInnerHTML={{ __html: "Contact & Support" }} />
                        <div className={`${baseClass}__info-section__sub-section__items`}>
                            {contact.map((item, i) => { return (<div key={i} dangerouslySetInnerHTML={{ __html: item }} />) })}
                        </div>
                    </div>
                </div>
                <div className={`${baseClass}__section-separator`} />
                {links && links.length > 0 &&
                    <div className={`${baseClass}__social-section`}>
                        {links.map((link, i) => { return (<a href={link.url} key={i} dangerouslySetInnerHTML={{ __html: link.name }} />); })}
                    </div>
                }
            </div>
        </div>
    )
}

export default Footer;