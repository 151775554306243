import React, { useState, useEffect } from 'react';
import Login from './Login';
import Signup from './Signup';
import Profile from './Profile';
import { useFirebase } from '../../context/Firebase';
import { doc, getDoc } from 'firebase/firestore';

const HeaderAuthModal = (props) => {
    const baseClass = "header-auth-modal";
    const [userData, setUserData] = useState({email: '', gender: ''});
    const { firestore } = useFirebase();

    useEffect(() => {
        const getUserData = async () => {
            const userData = await getDoc(doc(firestore, 'users', props.user.uid));
            if(userData._document !== null) {
                const data = userData._document.data.value.mapValue.fields;
                setUserData({
                    email: data.email.stringValue,
                    gender: data.gender.stringValue
                });
            }
        }

        if(props.user) {
            getUserData();
        }
    }, [props.user, firestore]);

    return (
        <div className={baseClass} onClick={(e) => {if (e.target.className === baseClass) props.closeModal()}}>
            <div className={`${baseClass}__container`}>
                <i className="fa-solid fa-xmark" onClick={() => props.closeModal()} />
                {props.user ? props.user.displayName ? 
                    <Profile user={props.user} userData={userData} clearAuth={() => props.clearAuth()} closeModal={() => props.closeModal()} />
                    : <Signup closeModal={() => props.closeModal()} />
                    : <Login closeModal={() => props.closeModal()} />
                }
            </div>
        </div>
    )
}

export default HeaderAuthModal;