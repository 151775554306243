import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { useFirebase } from '../context/Firebase';
import Menu from '../modules/menu/Menu';

const Services = () => {
  const baseClass = "service-page";

  const params = useParams();

  const [title, setTitle] = useState("");
  const [menuCards, setMenuCards] = useState({});
  const { firestore } = useFirebase();

  useEffect(() => {
    const getMenuData = async () => {
      const serviceDatabase = await getDocs(collection(firestore, 'services'));
      const menuDatabase = await getDocs(collection(firestore, 'menus'));
      serviceDatabase.docs.forEach((val) => {
        var serviceName = val._document.data.value.mapValue.fields.name.stringValue;
        if(serviceName.toLowerCase().split(' ').join('-') === params.name) {
          setTitle(serviceName);
          const menus = val._document.data.value.mapValue.fields.menus;
          const menuNamesArray = menus ? menus.arrayValue.values.map((data) => {
            return data.stringValue;
          }) : null;
          if(menuNamesArray && menuNamesArray.length > 0) {
            var menuItems = [];
            menuDatabase.docs.forEach((menu) => {
              if(menuNamesArray.includes(menu.id)) {
                var fields = menu._document.data.value.mapValue.fields;
                menuItems.push({
                  image: fields.image ? fields.image.stringValue : null,
                  title: fields.title ? fields.title.stringValue : null,
                  description: fields.description ? fields.description.stringValue : null,
                  price: fields.price ? fields.price.integerValue : null,
                  time: fields.time ? fields.time.integerValue : null,
                  pointers: fields.pointers ? fields.pointers.arrayValue.values.map((point) => {
                      return point.stringValue;
                  }) : null
                })
              }
            });
            setMenuCards(menuItems);
          }
        }
      });
    }

    getMenuData();
  }, [params.name, firestore]);  

  return (
    <div className={baseClass}>
      {menuCards && menuCards.length > 0 && <Menu title={title} menus={menuCards} />}
    </div>
  )
}

export default Services;