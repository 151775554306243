import React, { useEffect, useState } from 'react';
import BookServicesCard from './BookServicesCard';
import { Pagination, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

const BookServicesCarousel = (props) => {
    const baseClass = "book-services-carousel";

    const [width, setWidth] = useState(window.innerWidth > 1156 ? 1156 : window.innerWidth);

    const detectCurrentWidth = () => setWidth(window.innerWidth > 1156 ? 1156 : window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', detectCurrentWidth);
        return () => {
            window.removeEventListener('resize', detectCurrentWidth);
        }
    }, [width]);


    return (
        <div className={baseClass}>
            <Swiper
                slidesPerView={(width * 0.9) / 250}
                spaceBetween={30}
                pagination={{
                    type: 'progressbar',
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className={`${baseClass}__cards-wrapper`}
            >
                {props.serviceCards.map((serviceCard, i) => {
                    return (
                        <SwiperSlide key={i}>
                            <BookServicesCard {...serviceCard} />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </div>
    )
}

export default BookServicesCarousel;