import React, { useEffect, useState } from 'react';
import StandardsCard from './StandardsCard';
import { collection, getDocs } from 'firebase/firestore';
import { useFirebase } from '../../context/Firebase';

const Standards = (props) => {
    const baseClass = "standards";

    const [standardsCards, setStandardsCards] = useState([]);
    const { firestore } = useFirebase();

    useEffect(() => {
        const getStandardData = async () => {
            const database = await getDocs(collection(firestore, 'standards'));
            setStandardsCards(database.docs.map((val) => {
                return val._document.data.value.mapValue.fields
            }).map((data) => {
                return {
                    image: data.image.stringValue,
                    title: data.title.stringValue,
                    description: data.description.stringValue
                }
            }));
        }

        getStandardData();
    }, [firestore]);

    

    return (
        standardsCards && standardsCards.length > 0 && <div className={baseClass}>
            <div className={`${baseClass}__container`}>
                <div className={`${baseClass}__title`} dangerouslySetInnerHTML={{__html: props.title}} />
                <div className={`${baseClass}__cards-wrapper`}>
                    {standardsCards.map((standardsCard, i) => {
                        return(
                            <StandardsCard
                                key={i}
                                {...standardsCard}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    )
}

export default Standards;