import React, { useEffect, useState } from 'react';
import TopBanner from '../modules/banner/TopBanner'
import BookServices from '../modules/book-services/BookServices';
import Standards from '../modules/standards/Standards';
import MiddleBanner from '../modules/banner/MiddleBanner';
import Testimonial from '../modules/testimonial/Testimonial';
import AboutUs from '../modules/about-us/AboutUs';
import { collection, getDocs } from 'firebase/firestore';
import { useFirebase } from '../context/Firebase';

const Home = (props) => {
    const baseClass = "home-page";

    const [titles, setTitles] = useState({});
    const { firestore } = useFirebase();

    useEffect(() => {
        const getTitlesData = async () => {
            const database = await getDocs(collection(firestore, 'titles'));
            var titleObj = {};
            for (let doc of database.docs) {
                titleObj[doc.id] = doc._document.data.value.mapValue.fields.title.stringValue;
            }
            setTitles(titleObj);
        }

        getTitlesData();
    }, [firestore]);

    return (
        <div className={baseClass}>
            <TopBanner carousel={Boolean(props.user)} />
            {Boolean(props.user) && <BookServices title={titles["services"]} />}
            {Boolean(props.user) && <Standards title={titles["standards"]} />}
            {Boolean(props.user) && <MiddleBanner />}
            {Boolean(props.user) && <Testimonial title={titles["testimonials"]} />}
            {Boolean(props.user) && <AboutUs title={titles["aboutus"]} />}
        </div>
    )
}

export default Home;