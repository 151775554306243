import React, { useEffect, useState } from 'react';
import BookServicesCarousel from './BookServicesCarousel';
import { collection, getDocs } from 'firebase/firestore';
import { useFirebase } from '../../context/Firebase';

const BookServices = (props) => {
    const baseClass = "book-services";

    const [serviceCards, setServiceCards] = useState([]);
    const { firestore } = useFirebase();

    useEffect(() => {
        const getServiceData = async () => {
            const database = await getDocs(collection(firestore, 'services'));
            var services = [];
            database.docs.forEach((val) => {
                services.push({
                    name: val._document.data.value.mapValue.fields.name.stringValue,
                    image: val._document.data.value.mapValue.fields.image.stringValue,
                    id: val.id
                })
            });
            setServiceCards(services);
        }

        getServiceData();
    }, [firestore]);

    return (
        serviceCards && serviceCards.length > 0 &&
        <div className={baseClass}>
            <div className={`${baseClass}__container`}>
                <div className={`${baseClass}__title`} dangerouslySetInnerHTML={{ __html: props.title }} />
                <BookServicesCarousel
                    serviceCards={serviceCards}
                />
            </div>
        </div>
    )
}

export default BookServices;